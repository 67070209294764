<template>
  <!-- Login Module -->
  <v-card
    width="400"
    class="mx-auto mt-5"
  >
    <v-card-title>
      <h1 class="display-1">
        Login
      </h1>
    </v-card-title>
    <v-card-text>
      <v-alert
        v-if="error"
        dense
        transition="scale-transition"
        type="error"
      >
        {{
          error
        }}
      </v-alert>
      <v-form @submit.prevent="login">
        <v-text-field
          v-model="email"
          label="Email"
          prepend-icon="mdi-account-circle"
        />
        <v-text-field
          v-model="password"
          :type="showPassword ? 'text' : 'password'"
          label="Password"
          prepend-icon="mdi-lock"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
        />
      </v-form>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-btn
        color="info"
        @click="login"
      >
        Login
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "LoginPage",
  data() {
    return {
      showPassword: false,
      email: "",
      password: "",
      error: null,
    };
  },
  methods: {
    login() {
      this.$store
        .dispatch("login", {
          email: this.email,
          password: this.password,
        })
        .then(() => {
          // this.$router.push({ name: "messages" });
          this.$router.push(this.$route.query.redirect || '/messages')
        })
        .catch((err) => {
          console.log(err);
          // this.error = err.response.data.conversation;
        });
    },
  },
};
</script>

<style></style>
